import React from 'react';
import {
    List, Datagrid, TextField,
    TextInput,
    Create, Edit, SimpleForm,
    required, EditButton, ImageInput, ImageField, BulkDeleteWithConfirmButton
} from 'react-admin';
import {DateFieldForTimestamp} from "../mui/DateFromTimestamp";
import {CustomToolbar} from "../mui/layout/EditToolBar";


export const CompanyList = (props) => (
    <List title="Companies" {...props} sort={{field: 'name', order: 'ASC'}}
          bulkActionButtons={<BulkDeleteWithConfirmButton/>}>
        <Datagrid>
            <TextField source="id"/>
            <TextField label="Name" source="name"/>
            <ImageField label="Image" source="image.src"/>
            <DateFieldForTimestamp source="created"/>
            <EditButton/>
        </Datagrid>
    </List>
);


export const CompanyEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar />} toolbar={<CustomToolbar />}>
            <TextInput fullWidth label="Name" source="name" validate={[required()]}/>
            <ImageInput source="image" label="Image" accept="image/*">
                <ImageField source="src" title="title"/>
            </ImageInput>
        </SimpleForm>
    </Edit>
);

export const CompanyCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar />} toolbar={<CustomToolbar />}>
            <TextInput fullWidth label="Name" source="name" validate={[required()]}/>
            <ImageInput source="image" label="Image" accept="image/*">
                <ImageField source="src" title="title"/>
            </ImageInput>
        </SimpleForm>
    </Create>
);
