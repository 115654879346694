import React from 'react';
import {
    List, Datagrid, TextField, FunctionField,
    TextInput, Filter, NumberField,
    Create, Edit, SimpleForm, SelectInput,
    required, EditButton, ImageInput, ImageField,
    NumberInput, BulkDeleteWithConfirmButton, ReferenceInput
} from 'react-admin';
import {DateFieldForTimestamp} from "../mui/DateFromTimestamp";
import UpDownButton from "../buttons/UpDownPositionReverseButtons";
import {SubcategoryButton} from "../buttons/SubcategoryLink";
import {CustomToolbar} from "../mui/layout/EditToolBar";

export const CategoryList = (props) => {
    return (
        <List title="Categories" {...props} sort={{field: 'position', order: 'ASC'}}
              bulkActionButtons={<BulkDeleteWithConfirmButton/>}>
            <Datagrid>
                <TextField source="id"/>
                <TextField label="Name" source="name"/>
                <ImageField label="Image" source="image.src"/>
                <ImageField style={{backgroundColor: 'darkgray', display: 'inline-block'}} label="Image Hover"
                            source="image_clicked.src"/>
                <TextField style={{maxWidth: "20%"}} label="Url" source="url"/>
                <TextField label="Company" source="company.name"/>
                <TextField label="Description" source="description"/>
                <TextField label="Position" source="position"/>
                <DateFieldForTimestamp source="created"/>
                <SubcategoryButton/>
                <UpDownButton {...props}/>
                <EditButton/>
            </Datagrid>
        </List>
    )
}


export const CategoryEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
            <TextInput fullWidth label="Name" source="name" validate={[required()]}/>
            <TextInput multiline fullWidth label="Url" source="url"/>
            <TextInput fullWidth label="Name" source="name" validate={[required()]}/>
            <ReferenceInput fullWidth label="Company" source="company.id" reference="companies"
                            sort={{field: 'name', order: 'ASC'}} allowEmpty>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput multiline fullWidth label="Description" source="description"/>
            <ImageInput source="image" label="Image" accept="image/*">
                <ImageField source="src" title="title"/>
            </ImageInput>
            <ImageInput source="image_clicked" label="Image Clicked" accept="image/*">
                <ImageField style={{backgroundColor: 'darkgray'}} source="src" title="title"/>
            </ImageInput>
        </SimpleForm>
    </Edit>
);

export const CategoryCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
            <TextInput fullWidth label="Name" source="name" validate={[required()]}/>
            <TextInput multiline fullWidth label="Url" source="url"/>
            <ReferenceInput fullWidth label="Company" source="company.id" reference="companies"
                            sort={{field: 'name', order: 'ASC'}} allowEmpty>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput multiline fullWidth label="Description" source="description"/>
            <ImageInput source="image" label="Image" accept="image/*">
                <ImageField source="src" title="title"/>
            </ImageInput>
            <ImageInput source="image_clicked" label="Image Clicked" accept="image/*">
                <ImageField style={{backgroundColor: 'darkgray'}} source="src" title="title"/>
            </ImageInput>
        </SimpleForm>
    </Create>
);
