import React from 'react';
import {
    List, Datagrid, TextField, FunctionField,
    TextInput, Filter, BooleanInput, BooleanField,
    Create, Edit, SimpleForm, SelectInput,
    required, EditButton, ImageInput, ImageField,
    NumberInput, BulkDeleteWithConfirmButton, ReferenceInput
} from 'react-admin';
import {DateFieldForTimestamp} from "../mui/DateFromTimestamp";
import UpDownButton from "../buttons/UpDownPositionReverseButtons";
import {useStore} from 'react-redux';
import {CustomToolbar} from "../mui/layout/EditToolBar";
import {OfferButton} from "../buttons/OfferLink";

const WithProps = ({children, ...props}) => children(props);


export const SubcategoryList = (props) => {
    return (
        <>
            <List title="Subcategories" {...props} sort={{field: 'position', order: 'ASC'}}
                  bulkActionButtons={<BulkDeleteWithConfirmButton/>}>
                <Datagrid>
                    <TextField source="id"/>
                    <TextField label="Name" source="name"/>
                    <TextField label="GroupMain" source="data.groupMain"/>
                    <TextField label="Group" source="data.group"/>
                    <TextField label="Show page" source="show_page"/>
                    <TextField style={{maxWidth: "20%"}} label="Url" source="url"/>
                    <TextField label="Company" source="company.name"/>
                    <ImageField label="Image" source="image.src"/>
                    <ImageField style={{backgroundColor: 'darkgray', display: 'inline-block'}}
                                image={{style: "margin: 0 auto"}} label="Image Hover" source="image_clicked.src"/>
                    <TextField label="Description" source="description"/>
                    <TextField label="Position" source="position"/>
                    <DateFieldForTimestamp source="created"/>
                    <OfferButton/>
                    <UpDownButton {...props}/>
                    <EditButton/>
                </Datagrid>
            </List>
        </>
    )
};

const subcategoryCreateDefaultValue = (props) => {
    const store = useStore();
    return {category: {id: store.getState().category_id}}
};

const groupMainChoices = [
    {id: '', name: 'Nothing'},
    {id: 'Napa Valley', name: 'Napa Valley'},
]
const groupChoices = [
    {id: '', name: 'Nothing'},
    {id: 'Napa Downtown', name: 'Napa Downtown'},
    {id: 'Yountville', name: 'Yountville'},
    {id: 'Oakville', name: 'Oakville'},
    {id: 'Rutherford', name: 'Rutherford'},
    {id: 'St.Helena', name: 'St.Helena'},
    {id: 'Calistoga', name: 'Calistoga'}
]

export const SubcategoryEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list" defaultValue={subcategoryCreateDefaultValue({...props})} toolbar={<CustomToolbar/>}>
            <TextInput fullWidth label="Name" source="name" validate={[required()]}/>
            <SelectInput fullWidth label="Group Main" source="data.groupMain" choices={groupMainChoices}
                         optionText="name" emptyText='Nothing' emptyValue=''/>
            <SelectInput fullWidth optionText="name"  label="Group" source="data.group" choices={groupChoices} mptyText='Nothing' emptyValue=''/>
            <BooleanInput label="Show page" source="show_page" format={v => v == true ? 1 : 0}/>
            <TextInput multiline fullWidth label="Url" source="url"/>
            <ReferenceInput fullWidth label="Company" source="company.id" reference="companies"
                            sort={{field: 'name', order: 'ASC'}} allowEmpty>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput multiline fullWidth label="Description" source="description"/>
            <ImageInput source="image" label="Image" accept="image/*">
                <ImageField source="src" title="title"/>
            </ImageInput>
            <ImageInput source="image_clicked" label="Image Clicked" accept="image/*">
                <ImageField style={{backgroundColor: 'darkgray'}} source="src" title="title"/>
            </ImageInput>
        </SimpleForm>
    </Edit>
);


export const SubcategoryCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list" defaultValue={subcategoryCreateDefaultValue({...props})} toolbar={<CustomToolbar/>}>
            <TextInput fullWidth label="Name" source="name" validate={[required()]}/>
            <SelectInput fullWidth label="Group Main" source="groupMain" choices={groupMainChoices}
                         optionText="name" emptyText='Nothing' emptyValue=''/>
            <SelectInput fullWidth optionText="name"  label="Group" source="group" choices={groupChoices} emptyText='Nothing' emptyValue=''/>
            <BooleanInput label="Show page" source="show_page" defaultValue="0" format={v => v == true ? 1 : 0}/>
            <TextInput multiline fullWidth label="Url" source="url"/>
            <ReferenceInput fullWidth label="Company" source="company.id" reference="companies"
                            sort={{field: 'name', order: 'ASC'}} allowEmpty>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput multiline fullWidth label="Description" source="description"/>
            <ImageInput source="image" label="Image" accept="image/*">
                <ImageField source="src" title="title"/>
            </ImageInput>
            <ImageInput source="image_clicked" label="Image Clicked" accept="image/*">
                <ImageField style={{backgroundColor: 'darkgray'}} source="src" title="title"/>
            </ImageInput>
        </SimpleForm>
    </Create>
);
