import {AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS, CREATE} from 'react-admin';
import {ADMIN_USERS, SESSION, MICROSERVICE} from './local';
import md5 from 'crypto-js/md5';
import restclient from "./restclient";


export let userPermissionsService;

export default async(type, params) => {
    if (type === AUTH_LOGIN) {
        const {username, password} = params;
        if (ADMIN_USERS.hasOwnProperty(username) && ADMIN_USERS[username] === md5(password).toString()) {
            sessionStorage.setItem('sid', Math.round(Date.now() / 1000));
            sessionStorage.setItem('role', 'admin');
            sessionStorage.setItem('email', username);
            console.log('Authorization success')
            return Promise.resolve();
        } else {
            try {
                let res = await restclient(CREATE, 'auth', {
                    data: {
                        email: username,
                        password
                    }
                });
                sessionStorage.setItem('sid', Math.round(Date.now() / 1000));
                sessionStorage.setItem('role', res.data.user._role);
                sessionStorage.setItem('email', res.data.user._email);
                return Promise.resolve();
            } catch (e) {
                return Promise.reject('You are not autorized');
            }
        }


    }
    if (type === AUTH_LOGOUT) {
        sessionStorage.removeItem('sid');
        sessionStorage.removeItem('role');
        sessionStorage.removeItem('email');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const status = params.message.status;
        if (status === 401 || status === 403) {
            sessionStorage.removeItem('sid');
            sessionStorage.removeItem('role');
            sessionStorage.removeItem('email');
            return Promise.reject();
        }
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        const sid = sessionStorage.getItem('sid');
        if (!sid || (parseInt(sid) + parseInt(SESSION.PERIOD)) < Math.round(Date.now() / 1000)) return Promise.reject();
        return Promise.resolve(sid);
    }
    if (type === AUTH_GET_PERMISSIONS) {
        const role = sessionStorage.getItem('role');
        return Promise.resolve(role);
    }

    return Promise.reject('Unknown method');
};

export function withActions(wrapperActions)
{
    console.log('wrapperActions', wrapperActions)
}

