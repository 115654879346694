import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    TextInput,
    Filter,
    ImageField,
    Create,
    Edit,
    SimpleForm,
    PasswordInput,
    EditButton,
    BulkDeleteButton,
    FormWithRedirect,
    regex,
    email,
    SimpleFormIterator,
    useInput,
    ArrayInput,
    FormDataConsumer,
    BulkDeleteWithConfirmButton,
    ImageInput,
    ReferenceInput,
    SelectInput,
    ArrayField,
    required, AutocompleteArrayInput, ReferenceArrayInput, SelectArrayInput,
} from 'react-admin';
import {DateFieldForTimestamp} from "../mui/DateFromTimestamp";
import {CustomToolbar} from "../mui/layout/EditToolBar";
import Avatar from '@material-ui/core/Avatar';
import {MICROSERVICE} from '../local';
import {makeStyles} from '@material-ui/core/styles';
import {ChatterButton} from "../buttons/ChatterButton";
import * as React from 'react';
import {Fragment, useMemo} from 'react';
import {ChatterBulkButton} from "../buttons/ChatterBulkButton";
import {useForm} from 'react-final-form';
import {hotelCategoryChatterType} from "../constants/hotel";
import {Loading, Error, useQueryWithStore, useNotify, useRefresh, useRedirect, useUnselectAll} from 'react-admin';

const UserBulkActionButtons = props => (
    <Fragment>
        <ChatterBulkButton {...props}/>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);

const UsersFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="credentials" alwaysOn/>
    </Filter>);

const credentialType = ['Email', 'Phone']

export const AdminUserList = (props) => (
    <List bulkActionButtons={<UserBulkActionButtons/>} title="Admin Users" {...props}
          sort={{field: 'created', order: 'DESC'}}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="email"></TextField>
            <ArrayField source="hotels" sortable={false}>
                <Datagrid sortable={"false"}>
                    <TextField label="Name" source="hotel.name"/>
                </Datagrid>
            </ArrayField>
            <EditButton/>
        </Datagrid>
    </List>
);

export const AdminUserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
            <TextInput fullWidth label="Email" source="email" validate={[required(), email()]}/>
            <PasswordInput fullWidth label="New Password" source="newPassword"
                           inputProps={{autoComplete: 'new-password'}}/>

            {/*<ArrayInput source="hotels">
                <SimpleFormIterator>
                    <FormDataConsumer>
                        {({getSource, scopedFormData, ...rest}) => {
                            return (
                                <TextInput fullWidth
                                           source={getSource('name')}
                                />
                            );
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>*/}


            <ReferenceArrayInput fullWidth label="Hotels" source="hotels" reference="hotels"
                                 sort={{field: 'name', order: 'ASC'}}>
                <AutocompleteArrayInput fullWidth optionText="name"
                                        options={{inputProps: {autoComplete: 'new-value'}}}/>
            </ReferenceArrayInput>

        </SimpleForm>
    </Edit>
);
export const AdminUserCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
            <TextInput fullWidth label="Email" source="email" validate={[required(), email()]}/>
            <PasswordInput fullWidth label="Password" source="password" validate={[required()]}
                           inputProps={{autoComplete: 'new-password'}}/>

            <ReferenceArrayInput fullWidth label="Hotels" source="hotels" reference="hotels"
                                 sort={{field: 'name', order: 'ASC'}}>
                <AutocompleteArrayInput fullWidth optionText="name"
                                        options={{inputProps: {autoComplete: 'new-value'}}}/>
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);
