import * as React from "react";
import {
    Toolbar,
    SaveButton,
    DeleteWithConfirmButton
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

export const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton/>
        <DeleteWithConfirmButton undoable={false}/>
    </Toolbar>
);