import React from 'react';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import {stringify} from 'query-string';


export const SubcategoryButton = ({record}) => {
    return record ? (
        <Button
            variant="contained"
            component={Link}
            to={{
                pathname: '/subcategories',
                search: stringify({

                    filter: JSON.stringify({category_id: record.id}),
                })
            }}
            style={{overflow: 'inherit', backgroundColor: 'red', color: 'white'}}
        >Subcategories</Button>
    ) : null
}
