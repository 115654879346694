import React from 'react';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import {stringify} from 'query-string';

export const OfferButton = ({record}) => {
    return record ? (
        <Button
            variant="contained"
            component={Link}
            to={{
                pathname: '/offerss',
                search: stringify({
                    page: 1,
                    perPage: 25,
                    sort: 'position',
                    order: 'DESC',
                    filter: JSON.stringify({
                        category_id: record.category.id,
                        subcategory_id: record.id,
                        expiration_date: 'active'
                    }),
                }),
            }}
            style={{overflow: 'inherit', backgroundColor: 'red', color: 'white'}}
        >Offers</Button>
    ) : null
}
