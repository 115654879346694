import React from 'react';
import Button from '@material-ui/core/Button';
import SmsIcon from '@material-ui/icons/Sms';
import {Link} from 'react-router-dom';

export const ChatterButton = (props) => {
     return <Button
        variant="outlined"
        color="secondary"
        startIcon={<SmsIcon/>}
        component={Link}
        to={{
            pathname: '/chatter',
            state: props.record,
            basePath: props.basePath,
        }}
        style={{overflow: 'inherit'}}
    >Chatter</Button>
};

