import * as React from "react";
import {Mutation, useNotify, Button, useRefresh, UPDATE} from 'react-admin';

const UpDownButton = ({record, resource}) => {
    if (!record) return (<></>);
    const notify = useNotify();
    const refresh = useRefresh();
    const upPayload = {
        id: record.id,
        data: {
            ...record,
            position: record.position + 1,
        },
        previousData: {...record}
    }

    const downPayload = {
        id: record.id,
        data: {
            ...record,
            position: record.position - 1,
        },
        previousData: {...record}
    }

    const options = {
        undoable: false,
        onSuccess: () => {
            refresh();
        },
        onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
    };
    return (
        <>
            {record && record._canUp && <Mutation
                type={UPDATE}
                resource={resource}
                payload={upPayload}
                options={options}
            >
                {(upPosition, {loading}) => (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={upPosition}
                        disabled={loading}
                        label="Up"

                    />)}
            </Mutation>}
            {record && record._canDown && <Mutation
                type={UPDATE}
                resource={resource}
                payload={downPayload}
                options={options}
            >
                {(downPosition, {loading}) => (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={downPosition}
                        disabled={loading}
                        label="Down"

                    />)}
            </Mutation>}
        </>
    );
};

export default UpDownButton;
