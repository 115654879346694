import React from 'react';
import {Card, CardHeader} from '@material-ui/core';
import {APP} from './local';
import Toolbar from "@material-ui/core/Toolbar";

export default () => (
    <Card style={{margin: '2em'}}>
        <CardHeader style={{textAlign: "center"}} title={"Welcome to the " + APP.NAME + " administration"}/>
        <Toolbar style={{justifyContent: "center", alignItems: "center"}}>
            <img style={{marginBottom: 10}} alt={APP.NAME + " logo"} src={APP.LOGO} />
        </Toolbar>
    </Card>
);