import React from 'react';
import {
    List, Datagrid, TextField,
    TextInput, BooleanInput,
    Create, Edit, SimpleForm, SelectInput,
    required, EditButton, ImageInput, ImageField,
    NumberInput, BulkDeleteWithConfirmButton, ReferenceInput, email, minValue
} from 'react-admin';
import {DateFieldForTimestamp} from "../mui/DateFromTimestamp";
import UpDownButton from "../buttons/UpDownPositionButtons";
import {CustomToolbar} from "../mui/layout/EditToolBar";
import {Box, Card, Typography} from "@material-ui/core";
import {isValidPhoneNumber} from 'libphonenumber-js'

const validatePhone = (value) => {
    if (!value) return undefined;
    if (!isValidPhoneNumber(value)) {
        return 'Must be a valid Phone';
    }
};

const validateEmails = (value) => {
    if (!value) return undefined;
    let emails = value.split(',');
    for (let i = 0; i < emails.length; i++) {
        let email = emails[i].trim();
        if (!validateEmail(email)) {
            return email + ' not valid Email';
        }
    }
};

const validateEmail = (email) =>
    email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );


export const HotelList = ({permissions, ...props}) => (
    <List title="Hotels" {...props} sort={{field: 'position', order: 'DESC'}}
          bulkActionButtons={permissions === 'admin' &&
              <BulkDeleteWithConfirmButton resource="hotels" selectedIds={[]}/>}
    >
        <Datagrid>
            <TextField source="id"/>
            <ImageField label="Company" source="company._image.src"/>
            <TextField label="Name" source="name"/>
            <TextField label="Url" source="url"/>
            <ImageField label="Image" source="image.src"/>
            <TextField label="Position" source="position"/>
            <DateFieldForTimestamp source="created"/>
            {permissions === 'admin' && <UpDownButton {...props}/>}
            <EditButton/>
        </Datagrid>
    </List>
);


export const HotelEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
            <ReferenceInput fullWidth label="Company" source="company.id" reference="companies"
                            validate={[required()]} sort={{field: 'name', order: 'ASC'}}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput fullWidth label="Name" source="name" validate={[required()]}/>
            <TextInput fullWidth label="Url" source="url"/>
            <ImageInput source="image" label="Image" accept="image/*">
                <ImageField source="src" title="title"/>
            </ImageInput>
            <BooleanInput label="Visible" source="visible" format={v => v == true ? 1 : 0}/>
            <Card fullWidth>
                <Typography variant="h5" component="div">Data</Typography>

                <h4>Hotel DESK</h4>
                <TextInput fullWidth label="Phone" validate={validatePhone} source="data.data.hotelDesk.phone"/>
                <TextInput fullWidth label="Emails" validate={validateEmails} source="data.data.hotelDesk.email"/>
                <TextInput multiline fullWidth label="SMS Chatter message"
                           source="data.data.hotelDesk.chatterMessage.sms"/>
                <TextInput multiline fullWidth label="SMS Geo message" source="data.data.hotelDesk.geoMessage.sms"/>
                <TextInput multiline fullWidth label="Email Chatter message"
                           source="data.data.hotelDesk.chatterMessage.email"/>
                <TextInput multiline fullWidth label="Email Geo message" source="data.data.hotelDesk.geoMessage.email"/>
                <h5>Notifications</h5>
                <Box
                    style={{
                        display: 'inline-flex',
                        padding: 10,
                    }}
                >
                    <BooleanInput label="SMS" source="data.data.hotelDesk.notifications.sms"/>
                    <BooleanInput label="Email" source="data.data.hotelDesk.notifications.email"/>
                </Box>
                <hr/>

                <h4>Housekeeping</h4>
                <TextInput fullWidth label="Phone" validate={validatePhone} source="data.data.housekeeping.phone"/>
                <TextInput fullWidth label="Emails" validate={validateEmails} source="data.data.housekeeping.email"/>
                <TextInput multiline fullWidth label="SMS Chatter message"
                           source="data.data.housekeeping.chatterMessage.sms"/>
                <TextInput multiline fullWidth label="Email Chatter message"
                           source="data.data.housekeeping.chatterMessage.email"/>
                <h5>Notifications</h5>
                <Box
                    style={{
                        display: 'inline-flex',
                        padding: 10,
                    }}
                >
                    <BooleanInput label="SMS" source="data.data.housekeeping.notifications.sms"/>
                    <BooleanInput label="Email" source="data.data.housekeeping.notifications.email"/>
                </Box>
                <hr/>

                <h4>Concierge/Room Service</h4>
                <TextInput fullWidth label="Phone" validate={validatePhone} source="data.data.concierge.phone"/>
                <TextInput fullWidth label="Emails" validate={validateEmails} source="data.data.concierge.email"/>
                <TextInput multiline fullWidth label="SMS Chatter message"
                           source="data.data.concierge.chatterMessage.sms"/>
                <TextInput multiline fullWidth label="Email Chatter message"
                           source="data.data.concierge.chatterMessage.email"/>
                <h5>Notifications</h5>
                <Box
                    style={{
                        display: 'inline-flex',
                        padding: 10,
                    }}
                >
                    <BooleanInput label="SMS" source="data.data.concierge.notifications.sms"/>
                    <BooleanInput label="Email" source="data.data.concierge.notifications.email"/>
                </Box>
                <hr/>

                <h4>General Manager</h4>
                <TextInput fullWidth label="Phone" validate={validatePhone} source="data.data.generalManager.phone"/>
                <TextInput fullWidth label="Emails" validate={validateEmails} source="data.data.generalManager.email"/>
                <TextInput multiline fullWidth label="SMS Chatter message"
                           source="data.data.generalManager.chatterMessage.sms"/>
                <TextInput multiline fullWidth label="Email Chatter message"
                           source="data.data.generalManager.chatterMessage.email"/>
                <h5>Notifications</h5>
                <Box
                    style={{
                        display: 'inline-flex',
                        padding: 10,
                    }}
                >
                    <BooleanInput label="SMS" source="data.data.generalManager.notifications.sms"/>
                    <BooleanInput label="Email" source="data.data.generalManager.notifications.email"/>
                </Box>
            </Card>


            <Card fullWidth>
                <Typography variant="h5" component="div">Chatter</Typography>
                <TextInput fullWidth label="Check In" source="data.data.chatter.checkin"/>
                <TextInput fullWidth label="Check Out" source="data.data.chatter.checkout"/>
                <TextInput fullWidth label="Stay" source="data.data.chatter.stay"/>
                <TextInput fullWidth label="Guest" source="data.data.chatter.guest"/>
                <h5>Notifications</h5>
                <Box
                    style={{
                        display: 'inline-flex',
                        padding: 10,
                    }}
                >
                    <BooleanInput label="SMS" source="data.data.chatter.notifications.sms"/>
                    <BooleanInput label="Emails" source="data.data.chatter.notifications.email"/>
                </Box>
            </Card>
            <Card fullWidth>
                <Typography variant="h5" component="div">GEO</Typography>
                <NumberInput fullWidth label="Lat" min="-180.000000000000000" max="180.000000000000000"
                             source="geolocation.lat"
                             step="0.00000000000001"/>
                <NumberInput fullWidth label="Lng" min="-90.000000000000000" max="90.000000000000000"
                             source="geolocation.lng" step="0.00000000000001"/>
                <NumberInput fullWidth label="Distance, meters" source="geolocation.distance" defaultValue="100"/>
                <NumberInput fullWidth label="SMS Period, seconds" source="geolocation.smsPeriod" defaultValue="600"
                             min="600"/>
            </Card>
        </SimpleForm>
    </Edit>
);

export const HotelCreate = ({permissions, ...props}) => (
    <Create {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
            <ReferenceInput fullWidth label="Company" source="company" reference="companies"
                            validate={[required()]} sort={{field: 'name', order: 'ASC'}}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput fullWidth label="Name" source="name" validate={[required()]}/>
            <TextInput fullWidth label="Url" source="url"/>
            <ImageInput source="image" label="Image" accept="image/*">
                <ImageField source="src" title="title"/>
            </ImageInput>
            <BooleanInput label="Visible" source="visible" defaultValue="1" format={v => v == true ? 1 : 0}/>
            <Card fullWidth>
                <Typography variant="h5" component="div">Data</Typography>

                <h4>Hotel DESK</h4>
                <TextInput fullWidth label="Phone" validate={validatePhone} source="data.hotelDesk.phone"/>
                <TextInput fullWidth label="Emails" validate={validateEmails} source="data.hotelDesk.email"/>
                <TextInput multiline fullWidth label="SMS Chatter message"
                           source="data.hotelDesk.chatterMessage.sms"/>
                <TextInput multiline fullWidth label="SMS Geo message" source="data.hotelDesk.geoMessage.sms"/>
                <TextInput multiline fullWidth label="Email Chatter message"
                           source="data.hotelDesk.chatterMessage.email"/>
                <TextInput multiline fullWidth label="Email Geo message" source="data.hotelDesk.geoMessage.email"/>
                <h5>Notifications</h5>
                <Box
                    style={{
                        display: 'inline-flex',
                        padding: 10,
                    }}
                >
                    <BooleanInput label="SMS" source="data.hotelDesk.notifications.sms"/>
                    <BooleanInput label="Email" source="data.hotelDesk.notifications.email"/>
                </Box>
                <hr/>
                <h4>Housekeeping</h4>
                <TextInput fullWidth label="Phone" validate={validatePhone} source="data.housekeeping.phone"/>
                <TextInput fullWidth label="Emails" validate={validateEmails} source="data.housekeeping.email"/>
                <TextInput multiline fullWidth label="SMS Chatter message"
                           source="data.housekeeping.chatterMessage.sms"/>
                <TextInput multiline fullWidth label="Email Chatter message"
                           source="data.housekeeping.chatterMessage.email"/>
                <h5>Notifications</h5>

                <Box
                    style={{
                        display: 'inline-flex',
                        padding: 10,
                    }}
                >
                    <BooleanInput label="SMS" source="data.housekeeping.notifications.sms"/>
                    <BooleanInput label="Email" source="data.housekeeping.notifications.email"/>
                </Box>
                <hr/>

                <h4>Concierge/Room Service</h4>
                <TextInput fullWidth label="Phone" validate={validatePhone} source="data.concierge.phone"/>
                <TextInput fullWidth label="Emails" validate={validateEmails} source="data.concierge.email"/>
                <TextInput multiline fullWidth label="SMS Chatter message" source="data.concierge.chatterMessage.sms"/>
                <TextInput multiline fullWidth label="Email Chatter message"
                           source="data.concierge.chatterMessage.email"/>
                <h5>Notifications</h5>
                <Box
                    style={{
                        display: 'inline-flex',
                        padding: 10,
                    }}
                >
                    <BooleanInput label="SMS" source="data.concierge.notifications.sms"/>
                    <BooleanInput label="Email" source="data.concierge.notifications.email"/>
                </Box>
                <hr/>

                <h4>General Manager</h4>
                <TextInput fullWidth label="Phone" validate={validatePhone} source="data.generalManager.phone"/>
                <TextInput fullWidth label="Emails" validate={validateEmails} source="data.generalManager.email"/>
                <TextInput multiline fullWidth label="SMS Chatter message"
                           source="data.generalManager.chatterMessage.sms"/>
                <TextInput multiline fullWidth label="Email Chatter message"
                           source="data.generalManager.chatterMessage.email"/>
                <h5>Notifications</h5>
                <Box
                    style={{
                        display: 'inline-flex',
                        padding: 10,
                    }}
                >
                    <BooleanInput label="SMS" source="data.generalManager.notifications.sms"/>
                    <BooleanInput label="Email" source="data.generalManager.notifications.email"/>
                </Box>
            </Card>
            <Card fullWidth>
                <Typography variant="h5" component="div">Chatter</Typography>
                <TextInput fullWidth label="Check In" source="data.chatter.checkin"/>
                <TextInput fullWidth label="Check Out" source="data.chatter.checkout"/>
                <TextInput fullWidth label="Stay" source="data.chatter.stay"/>
                <TextInput fullWidth label="Guest" source="data.chatter.guest"/>
                <h5>Notifications</h5>
                <Box
                    style={{
                        display: 'inline-flex',
                        padding: 10,
                    }}
                >
                    <BooleanInput label="SMS" source="data.chatter.notifications.sms"/>
                    <BooleanInput label="Email" source="data.chatter.notifications.email"/>
                </Box>
            </Card>
            <Card fullWidth>
                <Typography variant="h5" component="div">GEO</Typography>
                <NumberInput fullWidth label="Lat" min="-180.000000000000000" max="180.000000000000000"
                             source="geolocation.lat"
                             step="0.00000000000001"/>
                <NumberInput fullWidth label="Lng" min="-90.000000000000000" max="90.000000000000000"
                             source="geolocation.lng" step="0.00000000000001"/>
                <NumberInput fullWidth label="Distance, meters" source="geolocation.distance" defaultValue="100"/>
                <NumberInput fullWidth label="SMS Period, seconds" source="geolocation.smsPeriod" defaultValue="600"
                             min="600" validate={[minValue(600)]}/>
            </Card>
        </SimpleForm>
    </Create>
);
