import {
    List, Datagrid, TextField, FunctionField,
    TextInput, Filter, ImageField,
    Create, Edit, SimpleForm, PasswordInput,
    EditButton, BulkDeleteButton, SimpleShowLayout,
    regex, email, SimpleFormIterator, useInput, Show,
    ArrayInput, FormDataConsumer, BulkDeleteWithConfirmButton, ImageInput, ReferenceInput, SelectInput,
} from 'react-admin';
import {DateFieldForTimestamp} from "../mui/DateFromTimestamp";
import {CustomToolbar} from "../mui/layout/EditToolBar";
import Avatar from '@material-ui/core/Avatar';
import {MICROSERVICE} from '../local';
import {makeStyles} from '@material-ui/core/styles';
import {ChatterButton} from "../buttons/ChatterButton";
import * as React from 'react';
import {Fragment, useMemo} from 'react';
import {ChatterBulkButton} from "../buttons/ChatterBulkButton";
import {useForm} from 'react-final-form';
import {hotelCategoryChatterType} from "../constants/hotel";
import {Loading, Error, useQueryWithStore, useNotify, useRefresh, useRedirect, useUnselectAll} from 'react-admin';

const UserBulkActionButtons = props => (
    <Fragment>
        <ChatterBulkButton {...props}/>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);

const UsersFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="credentials" alwaysOn/>
    </Filter>);

const credentialType = ['Email', 'Phone']

export const UserList = (props) => (
    <List bulkActionButtons={<UserBulkActionButtons/>} title="Users" {...props} filters={<UsersFilter/>}
          sort={{field: 'created', order: 'DESC'}}>
        <Datagrid>
            <FunctionField label="Avatar"
                           render={record =>
                               <>
                                   {record.params && record.params.length && record.params.filter(item => item._key == 'avatar').map(item => item._value).length ?
                                       <Avatar alt="Avatar"
                                               src={record.params.filter(item => item._key == 'avatar').map(item => MICROSERVICE.API_URL + '/files/' + item._value)[0]}/>
                                       : <Avatar alt="Empty avatar"/>}
                               </>
                           }/>
            <TextField source="id"/>
            <FunctionField label="Username"
                           render={record =>
                               <>
                                   {record.params && record.params.length ? record.params.filter(item => item._key == 'username').map(item => item._value) : null}
                               </>
                           }/>


            {credentialType.map((type, i) => (
                <FunctionField label={type} key={type + i}
                               render={record =>
                                   <span>
                                   {record.credentials && record.credentials.length ?
                                       <span>
                                           {record.credentials.filter((item) => {
                                               return item._type == type.toLowerCase();
                                           }).map(item => item._credential)

                                           }
                                       </span> : null}
                               </span>
                               }/>
            ))}
            <FunctionField label="Data"
                           render={record =>
                               <div>
                                   {record.params && record.params.length ?
                                       <table>
                                           <tbody>
                                           {record.params.map((item, i) => {
                                               if (item._key !== 'username' && item._key !== 'avatar' && item._key !== 'phone') {
                                                   return (
                                                       <tr>
                                                           <td>{item._key}:</td>
                                                           <td>{item._value}</td>
                                                       </tr>)
                                               }
                                               return null;
                                           })}
                                           </tbody>
                                       </table> : null}
                               </div>
                           }/>

            <DateFieldForTimestamp label="Registered" source="created" {...props} />
            <ChatterButton {...props} />
            <EditButton/>
        </Datagrid>
    </List>
);

const WithProps = ({children, ...props}) => children(props);
const validatePhone = '' || regex(/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm, 'Must be a valid Phone');

const useImageFieldStyles = makeStyles(theme => ({
    image: { // This will override the style of the <img> inside the <div>
        maxWidth: '5rem',
        maxHeight: '5rem',
    }
}));


export const UserEdit = (props) => {
    const imageFieldClasses = useImageFieldStyles();
    return (
        <Edit {...props}>
            <WithProps>{({record, ...props}) =>
                <SimpleForm record={record} {...props} toolbar={<CustomToolbar/>}>
                    <ImageInput source="image" label="Avatar" accept="image/*">
                        <ImageField source="src" title="title" classes={imageFieldClasses}/>
                    </ImageInput>
                    <ArrayInput source="credentials">
                        <SimpleFormIterator disableAdd disableRemove>
                            <FormDataConsumer>
                                {({getSource, scopedFormData, ...rest}) => {
                                    return (
                                        <TextInput fullWidth label={scopedFormData._type}
                                                   source={getSource('_credential')}
                                                   type={scopedFormData._type == 'email' ? 'email' : ''}
                                                   validate={scopedFormData._type == 'email' ? [email()] : []}/>
                                    );
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <TextInput fullWidth label="Username" source="username"/>
                    <PasswordInput fullWidth label="Password" source="password"
                                   inputProps={{autocomplete: 'new-password'}}/>
                </SimpleForm>}
            </WithProps>

        </Edit>
    )
};

export const UserShow = (props) => {
    const imageFieldClasses = useImageFieldStyles();
    return (
        <Show {...props}>
            <WithProps>{({record, ...props}) =>
                <SimpleShowLayout record={record} {...props} toolbar={<CustomToolbar/>}>
                    <FunctionField label="Avatar"
                                   render={record =>
                                       <>
                                           {record.params && record.params.length && record.params.filter(item => item._key == 'avatar').map(item => item._value).length ?
                                               <Avatar alt="Avatar"
                                                       src={record.params.filter(item => item._key == 'avatar').map(item => MICROSERVICE.API_URL + '/files/' + item._value)[0]}/>
                                               : <Avatar alt="Empty avatar"/>}
                                       </>
                                   }/>
                    {credentialType.map((type, i) => (
                        <FunctionField label={type} key={type + i}
                                       render={record =>
                                           <span>
                                   {record.credentials && record.credentials.length ?
                                       <span>
                                           {record.credentials.filter((item) => {
                                               return item._type == type.toLowerCase();
                                           }).map(item => item._credential)

                                           }
                                       </span> : null}
                               </span>
                                       }/>
                    ))}
                    <TextField fullWidth label="Username" source="username"/>
                </SimpleShowLayout>}
            </WithProps>
        </Show>
    )
};

export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
            <ImageInput source="image" label="Avatar" accept="image/*">
                <ImageField style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '5rem',
                    height: '5rem'
                }} source="src" title="title"/>
            </ImageInput>
            <TextInput fullWidth source="username"/>
            <TextInput fullWidth source="email" type="email" validate={[email()]}/>
            <TextInput fullWidth source="phone"/>
            <TextInput fullWidth label="Password" autoComplete="new-password" source="password"/>
        </SimpleForm>
    </Create>
);


const Chatter = ({hotelId}) => {
    const {data, loading, error} = useQueryWithStore({
        type: 'getOne',
        resource: 'hotels',
        payload: {id: hotelId}
    });

    if (loading) return <Loading/>;
    if (error) return <Error/>;
    if (!data) return null;
    let parsedData = [];
    if (data.data && data.data.data && data.data.data.chatter) {
        let chatter = data.data.data.chatter;
        let keys = Object.keys(chatter);
        hotelCategoryChatterType.map((val) => {
            if (keys.includes(val.id)) {
                parsedData.push({id: val.id, name: val.name, url: chatter[val.id]})
            } else {
                parsedData.push({id: val.id, name: val.name + ' (NA)', url: ''})
            }

        })
    } else {
        hotelCategoryChatterType.map((val) => {
            parsedData.push({id: val.id, name: val.name + ' (NA)', url: ''})
        })
    }
    return (
        <SelectInput fullWidth optionText="name" choices={parsedData} label="Type"
                     source="chatter"/>
    )
};


const UserSms = ({hotelId, chatterType}) => {
    let form = useForm();

    if (!hotelId) {
        form.change('sms', 'Please review on Pierre');
        return (
            <TextInput multiline fullWidth source="sms"/>
        )
    }
    const {data, loading, error} = useQueryWithStore({
        type: 'getOne',
        resource: 'hotels',
        payload: {id: hotelId}
    });

    if (loading) return <Loading/>;
    if (error) return <Error/>;
    if (!data || !data.data || !data.data.data || !data.data.data.chatter) {
        form.change('sms', 'Please review on Pierre ')
        return (
            <TextInput multiline fullWidth source="sms"/>
        )
    }

    let chatter = data.data.data.chatter;
    if (chatterType && chatter[chatterType]) {
        let sms = 'Please review ' + chatterType + ' on Pierre ' + chatter[chatterType];
        form.change('sms', sms)
    }
    if (!chatterType) {
        form.change('sms', 'Please review on Pierre ')
    }
    return (
        <TextInput multiline fullWidth source="sms"/>
    )
}

const UserPhones = (props) => {
    let record = props.location.state;
    let phone = '';
    if (!Array.isArray(record)) {
        phone = record.params && record.params.length ? record.params.filter(item => item._key === 'username').map(item => item._value)[0] : '';
        if (phone !== '') phone += ' ';
        phone += '(' + record.credentials.filter((item) => {
            return item._type == 'phone'
        }).map(item => item._credential)[0] + ')'

    } else {
        let ids = record;
        const {data, loading, error} = useQueryWithStore({
            type: 'getMany',
            resource: 'users',
            payload: {ids}
        });
        if (loading) {
            return <Loading/>;
        }
        if (error) {
            return <Error error={error}/>;
        }
        let phones = [];
        for (let i = 0; i < data.length; i++) {
            let phone = data[i].params && data[i].params.length ? data[i].params.filter(item => item._key === 'username').map(item => item._value)[0] : '';
            if (phone !== '') phone += ' ';
            phone += '(' + data[i].credentials.filter((item) => {
                return item._type == 'phone'
            }).map(item => item._credential)[0] + ')'
            phones.push(phone)
        }
        phone = phones.join(', ');
    }
    return <TextInput multiline fullWidth disabled source="phone" initialValue={phone}/>

}

export const UserChatter = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const unselectAll = useUnselectAll();

    const onFailure = (error) => {
        notify(`Could not send sms`);
        redirect('users/list');
        unselectAll('users');
    };

    const onSuccess = ({data}) => {
        notify(`SMS sent`);
        redirect('users/list');
        unselectAll('users');

    };

    {
        return (
            <Create {...props} basePath='/chatter' resource='chatter' title='Chatter SMS' onSuccess={onSuccess}
                    onFailure={onFailure}>
                <SimpleForm redirect="users/list">
                    <h4 style={{marginBottom: 0}}>Users:</h4>
                    <UserPhones {...props}/>
                    <ReferenceInput fullWidth label="Hotel" source="hotel" reference="hotels"
                                    sort={{field: 'position', order: 'DESC'}}>
                        <SelectInput optionText="name"/>
                    </ReferenceInput>
                    <FormDataConsumer subscription={{values: true}}>
                        {({formData, scopedFormData, ...rest}) => {
                            return (
                                formData && formData.hotel ? (
                                    <Chatter hotelId={formData.hotel}/>) : null);
                        }}
                    </FormDataConsumer>
                    <FormDataConsumer subscription={{values: true}}>
                        {({formData, ...rest}) => {
                            return (<UserSms hotelId={formData.hotel} chatterType={formData.chatter}/>)
                        }}
                    </FormDataConsumer>

                </SimpleForm>
            </Create>
        )
    }
}

