import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
    UPDATE_MANY,
} from 'react-admin';
import {CacheService} from "./casheService";
import {stringify} from 'query-string';
import {MICROSERVICE, TRANSLATE_MICROSERVICE} from './local';
//import uuid from "uuid/v1";
import {SSO} from './constants/message_templates'


const cacheService = new CacheService();
const CHANGE_POSITION = 'CHANGE_POSITION';

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertRESTRequestToHTTP = (type, resource, params) => {
        let url = '';
        const options = {};
        //const requestId = uuid();
        const email = sessionStorage.getItem('email');
        const role = sessionStorage.getItem('role');
        if (resource === 'offerss') resource = 'offers';
        const data = {params: {key: '', secret: ''}};

        console.log('REQUEST', type, resource, params, params.uid);
        switch (type) {
            case GET_LIST: {
                if (resource === 'hotels' && role !== 'admin') {
                    params.filter['auserEmail'] = email;
                }
                console.log('params.filter', params.filter)
                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;
                let query = {
                    filter: JSON.stringify(params.filter),
                    page,
                    limit: perPage,
                    sort: JSON.stringify({field, order})
                }
                /* if (resource === 'offers' && params.filter.subcategory_id) {
                     query.sort = '{"field":"position","order":"DESC"}';
                 }*/
                url = `${MICROSERVICE.API_URL}/admin/${resource}?${stringify(query)}`;
                break;
            }
            case GET_ONE:
                console.log('getting one', params, resource);
                if (resource == 'users') {
                    url = `${MICROSERVICE.API_URL}/admin/${resource}/${params.id}`
                } else {
                    url = `${MICROSERVICE.API_URL}/admin/${resource}/${params.id}`;
                }
                console.log('URLGETONE', url);
                break;
            case
            GET_MANY: {
                const query = {
                    filter: JSON.stringify({id: params.ids}),
                };
                url = `${MICROSERVICE.API_URL}/admin/${resource}?${stringify(query)}`;
                //url = `${MICROSERVICE.API_URL}/admin/${resource}`;
                console.log(url);
                break;
            }
            case
            GET_MANY_REFERENCE: {
                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;
                const query = {
                    sort: JSON.stringify({field, order}),
                    range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
                    filter: JSON.stringify({...params.filter, [params.target]: params.id}),
                };
                url = `${MICROSERVICE.API_URL}/admin/${resource}?${stringify(query)}`;
                break;
            }
            case
            UPDATE:
                url = `${MICROSERVICE.API_URL}/admin/${resource}/${params.id}`;
                options.method = 'PUT';
                options.body = JSON.stringify(params);
                break;
            case
            UPDATE_MANY:
                url = `${MICROSERVICE.API_URL}/admin/${resource}/${params.id}`;
                options.method = 'PUT';
                options.body = JSON.stringify(params);
                break;
            case
            CREATE:
                url = `${MICROSERVICE.API_URL}/admin/${resource}`;
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            case
            DELETE:
                url = `${MICROSERVICE.API_URL}/admin/${resource}/${params.id}`;
                options.method = 'DELETE';
                break;
            case
            DELETE_MANY:
                url = `${MICROSERVICE.API_URL}/admin/${resource}/${params.ids}`;
                options.method = 'DELETE';
                break;
            case
            SSO:
                url = `${TRANSLATE_MICROSERVICE.API_URL}/sso`;
                options.method = 'POST';
                data.name = 'sso';
                data.params.key = `${TRANSLATE_MICROSERVICE.KEY}`;
                data.params.secret = `${TRANSLATE_MICROSERVICE.SECRET}`;
                options.body = JSON.stringify(data);
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return {url, options};
    }
;

/**
 *
 * @param {string} resource
 * @param {Object} filter
 * @param sort
 * @returns {string}
 */
const getCacheKey = (resource, filter, sort) => {
    if (resource === 'offerss') resource = 'offers';
    if (sort === undefined) {
        sort = {field: 'id', order: 'DESC'};
    }

    if (filter === undefined) {
        filter = {};
    }
    if (Object.keys(filter).length) {
        // `${resource}-${filterNameA}-${filterNameB}-${filterNameC}-${filterValueA}-${filterValueB}-${filterValueC}`
        let key = `${resource}-${sort['field']}-${sort.order}-`;
        // Sort filter keys by alfabets
        const filterKeys = Object.keys(filter).sort();
        // convert array to string, concat with key
        key += filterKeys.join('-').replace(/\s/g, '_');
        // concat filter values to key
        filterKeys.forEach((propName) => {
            key += '-' + filter[propName].toString().replace(/\s/g, "_");
            console.log('KEY-----', key);
        });
        return key.toLowerCase();
    } else {
        return (`${resource}-${sort['field']}-${sort.order}`).toLowerCase();
    }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} REST response
 */
const convertHTTPResponseToREST = (response, type, resource, params) => {
        if (resource === 'offerss') resource = 'offers';
        const {json} = response;
        console.log('convertHTTPResponseToREST', json, type, resource);

        switch (type) {
            case GET_LIST:
                let data, total;
                data = json.items;
                total = json.pager.cnt || 0;
                console.log('RESOURCE', resource, data, total, params, resource);
                let dataret = getData(data, total, params, resource);
                console.log(dataret);
                return dataret;
            case GET_MANY_REFERENCE:

                var jsonData = json.data.map(function (dic) {
                    console.log('GET_MANY_REFERENCE1', dic)
                    var interDic = Object.assign(
                        {id: dic.id},
                        dic.attributes,
                        dic.meta
                    );
                    if (dic.relationships) {
                        Object.keys(dic.relationships).forEach(function (key) {
                            var keyString = key + "_id";
                            if (dic.relationships[key].data) {
                                //if relationships have a data field --> assume id in data field
                                interDic[keyString] = dic.relationships[key].data.id;
                            } else if (dic.relationships[key].links) {
                                //if relationships have a link field
                                var link = dic.relationships[key].links["self"];
                                this(link).then(function (response) {
                                    interDic[key] = {
                                        data: response.json.data,
                                        count: response.json.data.length
                                    };
                                    interDic["count"] = response.json.data.length;
                                });
                            }
                        });
                    }
                    return interDic;
                });
                return {data: json.data, total: json.meta["total"]};
            case GET_MANY:
                console.log('GET_MANY_REFERENCE', json);
                jsonData = json.items.map(function (obj) {
                    console.log(obj);

                    return Object.assign({id: obj.id}, obj);
                });
                console.log(jsonData);
                return {data: jsonData};
            case DELETE_MANY:
                return {data: json.data}
            // eslint-disable-next-line
            case CREATE:
            case DELETE:
            case UPDATE:
            case UPDATE_MANY:
            case GET_ONE:
                if (resource == 'offers') {
                    if (json && json.users && json.users.length) {
                        json.users = json.users.map(item => item.uid)
                    }
                    return {data: json};
                }
                if (resource == 'ausers') {
                    console.log('1',json.hotels)
                    if (json && json.hotels && json.hotels.length) {

                        json.hotels = json.hotels.map(item => item.id)
                    }
                    console.log('2',json.hotels)
                    return {data: json};
                }
                return {data: json};
            case SSO:
                return {data: json.data}
            case AUTH_LOGIN:
                return {data: json.data}
            default:
                console.log('Def', json)
                return {data: json.data};
        }
    }
;

const getData = (list, total, params, resource, customList = []) => {

    if (Object.keys(params.sort).length) {
        if (params.sort.order === 'ASC') {
            customList = list.sort((a, b) => {
                if (a[params.sort.field] === null && b[params.sort.field] !== null) {
                    return 1;
                }
                if (a[params.sort.field] !== null && b[params.sort.field] === null) {
                    return -1;
                }
                if (a[params.sort.field] === null && b[params.sort.field] === null) {
                    return 0;
                }
                if(params.sort.field === 'position'){
                    if (a[params.sort.field] > b[params.sort.field]) {
                        return 1;
                    }
                    if (a[params.sort.field] < b[params.sort.field]) {
                        return -1;
                    }
                }
                if (a[params.sort.field].toString().toLowerCase() > b[params.sort.field].toString().toLowerCase()) {
                    return 1;
                }
                if (a[params.sort.field].toString().toLowerCase() < b[params.sort.field].toString().toLowerCase()) {
                    return -1;
                }
                return 0;
            })
        }
        if (params.sort.order === 'DESC') {
            customList = list.sort((a, b) => {
                if (a[params.sort.field] === null && b[params.sort.field] !== null) {
                    return -1;
                }
                if (a[params.sort.field] !== null && b[params.sort.field] === null) {
                    return 1;
                }
                if (a[params.sort.field] === null && b[params.sort.field] === null) {
                    return 0;
                }
                if(params.sort.field === 'position'){
                    if (a[params.sort.field] > b[params.sort.field]) {
                        return -1;
                    }
                    if (a[params.sort.field] < b[params.sort.field]) {
                        return 1;
                    }
                }
                if (a[params.sort.field].toString().toLowerCase() > b[params.sort.field].toString().toLowerCase()) {
                    return -1;
                }
                if (a[params.sort.field].toString().toLowerCase() < b[params.sort.field].toString().toLowerCase()) {
                    return 1;
                }
                return 0;
            })
        }
    }

    customList.forEach((item, i) => {
        if (params.pagination.page > 1) {
            item.num = (i + 1) + ((params.pagination.page * params.pagination.perPage) - params.pagination.perPage);
            console.log('ITEMNUM', item.num);
        } else {
            item.num = i + 1;
        }
    });
    return {
        data: customList,
        total: total,
    };
};

/**
 * Maps admin-on-rest queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param params
 * @returns {Promise} the Promise for a REST response
 */
export default (type, resource, params) => {
    const {fetchJson} = fetchUtils;
    const {url, options} = convertRESTRequestToHTTP(type, resource, params);
    console.log('2', url, options);
    return fetchJson(url, options)
        .then(response => {
            console.log('response', response)
            // Handling errors from server
            if (response.json.code < 200 || response.json.code >= 300) {
                return Promise.reject({
                    message: {
                        status: response.json.code,
                        message: response.json.message
                    }
                });
            }
            return response;
        })
        .then(response => convertHTTPResponseToREST(response, type, resource, params))

};
