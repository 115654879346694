import React from 'react';
import {
    List, Datagrid, TextField, ImageField,
    TextInput, Filter, ReferenceField,
    Create, Edit, SimpleForm, SelectInput,
    required, EditButton, ReferenceInput, BulkDeleteWithConfirmButton
} from 'react-admin';
import {DateFieldForTimestamp} from "../mui/DateFromTimestamp";
import {CustomToolbar} from "../mui/layout/EditToolBar";

export const companies = [
    {id: "pierre", name: "Pierre"},
    {id: "ubereats.com", name: "Uber Eats"},
    {id: "chicknskin.com", name: "Chick N'skin"},
    {id: "marketwatch.com", name: "Market Watch"},
    {id: "mayoclinic.org", name: "Mayo Clinic"},
    {id: "macdon.com", name: "Mac Don"},
    {id: "bk.com", name: "Burger King"},
]


const NotificationsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="data.company" alwaysOn/>
    </Filter>);


export const NotificationList = (props) => (
    <List title="Notifications" {...props} sort={{field: 'created', order: 'DESC'}}  bulkActionButtons={<BulkDeleteWithConfirmButton/>}>
        <Datagrid>
            <TextField source="id"/>
            <TextField label="Notification" source="message"/>
            <TextField label="Offer" source="offer.message" />
            <ReferenceField fullWidth label="Company" source="data.company" reference="companies">
                <ImageField source="image.src"/>
            </ReferenceField>
            <DateFieldForTimestamp source="created"/>
            <EditButton/>
        </Datagrid>
    </List>
);


export const NotificationEdit = (props) => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
            <TextInput fullWidth label="Notification" source="message" validate={[required()]}/>
            <ReferenceInput fullWidth label="Company" source="data.company" reference="companies"
                            validate={[required()]}  sort={{ field: 'name', order: 'ASC' }}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const NotificationCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
            <TextInput fullWidth label="Notification" source="message" validate={[required()]}/>
            <ReferenceInput fullWidth label="Company" source="data.company" reference="companies"
                            validate={[required()]} sort={{ field: 'name', order: 'ASC' }}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
             {/*<SelectInput fullWidth label="Company" source="data.company" choices={companies}
                         validate={[required()]}/>*/}
        </SimpleForm>
    </Create>
);
