import {DateField} from 'react-admin';
import React from "react";

export const DateFieldForTimestamp = props => {
    if (props && props.source && props.record && props.record[props.source]) {
        const recordWithTimestampAsInteger = {
            [props.source]: parseInt(props.record[props.source], 10) * 1000
        };
        return <DateField {...props} record={recordWithTimestampAsInteger}/>
    } else {
        return null
    }
}