import React, {useMemo} from 'react';
import {
    List, Datagrid, TextField, AutocompleteArrayInput, SelectArrayInput,
    TextInput, Filter, DateTimeInput, ArrayInput, DateInput,
    Create, Edit, SimpleForm, SelectInput,
    required, EditButton, ImageInput, ImageField, DateField,
    ReferenceArrayInput, BulkDeleteWithConfirmButton, ReferenceInput,
} from 'react-admin';
import {DateFieldForTimestamp} from "../mui/DateFromTimestamp";
import UpDownButton from "../buttons/UpDownPositionButtons";
import {useStore, useLocation} from 'react-redux';
import {CustomToolbar} from "../mui/layout/EditToolBar";
import {makeStyles} from "@material-ui/core/styles";

const useImageFieldStyles = makeStyles(theme => ({
    image: { // This will override the style of the <img> inside the <div>
        maxWidth: '5rem',
        maxHeight: '5rem',
    }
}));

const offerStatus = [
    {id: 'all', name: 'All'},
    {id: 'active', name: 'Active'},
    {id: 'not_active', name: 'Not active'}];

const OfferFilter = (props) => {
    const store = useStore();
    if (store.getState().category_id && store.getState().subcategory_id) {
        return (
            <Filter {...props}>
                <SelectInput optionText="name" choices={offerStatus} source="expiration_date" alwaysOn
                             allowEmpty={false}/>
            </Filter>)
    } else {
        const subcategoryFilter = store.getState().category_id ? {category_id: store.getState().category_id} : {}
        return (
            <Filter {...props}>

                <ReferenceInput fullWidth label="Category" source="category_id" reference="categories"
                                sort={{field: 'name', order: 'ASC'}} alwaysOn
                                allowEmpty>
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <ReferenceInput fullWidth label="Subcategory" source="subcategory_id" reference="subcategories"
                                sort={{field: 'name', order: 'ASC'}}
                                filter={subcategoryFilter}
                                alwaysOn allowEmpty>
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <SelectInput optionText="name" choices={offerStatus} source="expiration_date" alwaysOn
                             allowEmpty={false}/>
            </Filter>)

    }
}


export const OfferList = (props) => {
    const store = useStore();
    const imageFieldClasses = useImageFieldStyles();
    let offerListDefaultValue = {expiration_date: 'active'};
    if (store.getState().category_id) offerListDefaultValue.category_id = store.getState().category_id;
    if (store.getState().subcategory_id) offerListDefaultValue.subcategory_id = store.getState().subcategory_id;
    let sort = {field: 'id', order: 'DESC'};
    return (
        <>
            <List title="Offers" {...props} sort={sort} filters={<OfferFilter/>}
                  filterDefaultValues={offerListDefaultValue}
                  bulkActionButtons={<BulkDeleteWithConfirmButton/>}>
                <Datagrid>
                    <TextField source="id"/>
                    <TextField style={{minWidth: '10rem', display: 'block'}} label="Message" source="message"/>
                    <ImageField classes={imageFieldClasses} label="Image" source="image.src"/>
                    <TextField label="Url" source="url"/>
                    <TextField label="Company" source="company.name"/>
                    <TextField label="Subcategory" source="subcategory.name"/>
                  {/*  <TextField label="Position" source="position"/>*/}
                    <DateField label="Expiration Date" source="expiration_date"/>
                    <DateFieldForTimestamp source="created"/>
                    <UpDownButton {...props}/>
                    <EditButton/>
                </Datagrid>
            </List>
        </>
    )
};

export const OfferEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
            <TextInput fullWidth label="Message" source="message" validate={[required()]}/>
            <ImageInput source="image" label="Image" accept="image/*" validate={[required()]}>
                <ImageField source="src" title="title"/>
            </ImageInput>
            <ReferenceInput fullWidth label="Company" source="company.id" reference="companies"
                            validate={[required()]} sort={{field: 'name', order: 'ASC'}}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput fullWidth label="Subcategory" source="subcategory.id" reference="subcategories"
                            validate={[required()]} sort={{field: 'name', order: 'ASC'}}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput fullWidth label="Url" source="url" validate={[required()]}/>
            <DateInput fullWidth source="expiration_date" validate={[required()]}/>
            <ReferenceArrayInput label="Users" reference="users" source="users"
                                 filterToQuery={searchText => ({credentials: searchText})}>
                <AutocompleteArrayInput
                    fullWidth
                    allowEmpty
                    optionText="optionNameForSelect2"
                />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);

const offerCreateDefaultValue = () => {
    const store = useStore();
    return {subcategory: {id: store.getState().subcategory_id}, expiration_date: (new Date())}
}

export const OfferCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list" defaultValue={offerCreateDefaultValue({...props})} toolbar={<CustomToolbar/>}>
            <TextInput fullWidth label="Message" source="message" validate={[required()]}/>
            <ImageInput source="image" label="Image" accept="image/*" validate={[required()]}>
                <ImageField source="src" title="title"/>
            </ImageInput>
            <ReferenceInput fullWidth label="Company" source="company.id" reference="companies"
                            validate={[required()]} sort={{field: 'name', order: 'ASC'}}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput fullWidth label="Subcategory" source="subcategory.id" reference="subcategories"
                            validate={[required()]} sort={{field: 'name', order: 'ASC'}}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput fullWidth label="Url" source="url" validate={[required()]}/>
            <DateInput fullWidth source="expiration_date" validate={[required()]}
                       defaultValue={(new Date()).toDateString()}/>
            <ReferenceArrayInput label="Users" reference="users" source="users"
                                 filterToQuery={searchText => ({credentials: searchText})}>
                <AutocompleteArrayInput
                    fullWidth
                    allowEmpty
                    optionText="optionNameForSelect2"
                />
            </ReferenceArrayInput>

        </SimpleForm>
    </Create>
);
