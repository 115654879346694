import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    ReferenceField,
    downloadCSV,
    BulkExportButton
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import {DateFieldForTimestamp} from "../mui/DateFromTimestamp";
import * as React from 'react';
import { Fragment } from 'react';


/*const UsersLoggedFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="credentials" alwaysOn/>
    </Filter>);

*/


const exporter = loggedUsers => {
    const loggedForExport = loggedUsers.map(loggedUsers => {
        const {user, logged, from} = loggedUsers;
        let loggedExport = {};
        loggedExport.user  = user;
        loggedExport.from = from;
        loggedExport.logged  = (new Date(parseInt(logged, 10) * 1000)).toLocaleString();
        return loggedExport;
    });
    jsonExport(loggedForExport, {
        headers: ['user', 'from', 'logged'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'logged_users');
    });
};

const UserLoggedBulkActionButtons = () => (
    <Fragment>
        <BulkExportButton />
    </Fragment>
);

export const AdminUserLoggedList = (props) => (
    <List title="Users Logged" {...props} exporter={exporter} bulkActionButtons={<UserLoggedBulkActionButtons />}
          sort={{field: 'logged', order: 'DESC'}}>
        <Datagrid>
            <ReferenceField fullWidth label="User" source="user" reference="users" link="show">
                <FunctionField label="Username"
                               render={record =>{
                                   return (
                                       <>
                                           <>
                                               Username : {record.params && record.params.length ? record.params.filter(item =>
                                               item._key == 'username').map(item => item._value) : null}
                                           </>
                                           <br/>
                                           <>
                                               Phone: {record.credentials && record.credentials.length ? record.credentials.filter(item => item._type == 'phone').map(item => item._credential) : null}
                                           </>
                                           <br/>

                                           <>
                                               Email:  {record.credentials && record.credentials.length ? record.credentials.filter(item => item._type == 'email').map(item => item._credential) : null}
                                           </>
                                           <br/>
                                       </>)
                               }}/>
            </ReferenceField>
            <TextField source="from"/>
            <DateFieldForTimestamp source="logged" showTime="true"/>
        </Datagrid>
    </List>
);
