import React from 'react';
import {Admin, Resource} from 'react-admin';
import {createBrowserHistory} from 'history';
import myApiRestClient from './restclient';
import Dashboard from './dashboard';
import authClient from './authClient';
import i18nProvider from './i18nProvider';
import {APP} from './local';
import Login from './mui/auth/Login';
import {NotificationCreate, NotificationEdit, NotificationList} from "./resources/notification";
import {UserCreate, UserEdit, UserList, UserShow} from "./resources/users";
import {CategoryCreate, CategoryEdit, CategoryList} from "./resources/categories";
import addUploadCapabilities from "./FileUploader";
import {SubcategoryCreate, SubcategoryEdit, SubcategoryList} from "./resources/subcategories";
import categoryIdReducer from "./reducers/categoryIdReducer";
import {CompanyCreate, CompanyEdit, CompanyList} from "./resources/companies";
import {HotelCreate, HotelEdit, HotelList} from "./resources/hotels";
import {OfferCreate, OfferEdit, OfferList} from "./resources/offers";
import {AdminUserList, AdminUserEdit, AdminUserCreate} from "./resources/admin_users";
import subcategoryIdReducer from "./reducers/subcategoryIdReducer";
import {createMuiTheme} from '@material-ui/core/styles';
import customRoutes from "./customRoutes";
import {AdminUserLoggedList} from "./resources/admin_users_logged";


var originalLog = console.error;
console.error = function log(...args) {
    if (args.length > 0 && typeof args[0] === "string" && /^Warning: Missing translation/.test(args[0])) {
        return
    }
    originalLog.apply(console, args)
};

const history = createBrowserHistory();
const uploadCapableClient = addUploadCapabilities(myApiRestClient);

/*
const myTheme = createMuiTheme({
    overrides:{
        MuiTableRow: {
            head: {
                backgroundColor: 'lightgray',
                "& > th ": {
                    color: 'black',
                    fontWeight: 'bold',
                }
            },
        }
    }
})
*/

const App = () => (
    <Admin locale="en"
           messages={i18nProvider}
           authProvider={authClient}
           dataProvider={uploadCapableClient}
           dashboard={Dashboard}
           customReducers={{category_id: categoryIdReducer, subcategory_id: subcategoryIdReducer}}
           history={history}
           title={`Welcome to the administration of ${APP.NAME}`}
           loginPage={Login}
           customRoutes={customRoutes}
        //    theme={myTheme}
    >{  permissions => [
        <Resource name="hotels" list={HotelList} edit={HotelEdit}  create={ permissions === 'admin' ? HotelCreate : null}/>,
        permissions === 'admin' ? <Resource name="notifications" list={NotificationList} edit={NotificationEdit} create={NotificationCreate}/> : null,
        permissions === 'admin' ? <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} show={UserShow}/> : null,
        permissions === 'admin' ? <Resource name="logged"  options={{label: "Logged Users"}} list={AdminUserLoggedList}/> : null,
        permissions === 'admin' ? <Resource name="categories" list={CategoryList} edit={CategoryEdit} create={CategoryCreate}/> : null,
        permissions === 'admin' ? <Resource name="subcategories" list={SubcategoryList} edit={SubcategoryEdit} create={SubcategoryCreate}/> : null,
        permissions === 'admin' ? <Resource name="companies" list={CompanyList} edit={CompanyEdit} create={CompanyCreate}/> : null,
        permissions === 'admin' ? <Resource name="offerss" list={OfferList} edit={OfferEdit} create={OfferCreate}/> : null,
        permissions === 'admin' ? <Resource name="offers" list={OfferList} edit={OfferEdit} create={OfferCreate}/> : null,

        permissions === 'admin' ?  <Resource options={{label: "Admin Users"}} name="ausers" list={AdminUserList} edit={AdminUserEdit}
                  create={AdminUserCreate}/> : null
    ]}
    </Admin>
);


export default App;
