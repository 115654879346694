import {CREATE, UPDATE} from 'react-admin';

const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile)

    reader.onload = () => {
        console.log(reader.result)
        resolve(reader.result)
    };
    reader.onerror = reject;
});


/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadCapabilities = requestHandler => async(type, resource, params) => {
    if ((type === CREATE || type === UPDATE) && ['categories', 'subcategories', 'companies', 'hotels', 'users', 'offerss'].includes(resource)) {
        // only freshly dropp
        if (params && params.data && (params.data.image || params.data.image_clicked)) {
            // only freshly dropped files are instance of File
            let newFiles = [];
            const newFile = params.data.image;
            const newClickedFile = params.data.image_clicked;
            console.log('params.data', params.data);
            if (newFile && newFile.rawFile instanceof File) newFiles.push(newFile);
            if (newClickedFile && newClickedFile.rawFile instanceof File) newFiles.push(newClickedFile);

            if (!newFiles.length) {
                return Promise.resolve(requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        oldFile: true,
                    }
                }));
            }

            let newFileT = {data: {file: null}};
            if (newFile && newFile.rawFile instanceof File) {

                newFileT = await convertFileToBase64(newFile)
                    .then((picture64) => ({
                        src: picture64,
                        title: `${newFile.title}`
                    }))
                    .then(transformedFile => ({
                        data: {
                            file: transformedFile,
                        }
                    }));

            }
            let newClickedFileT = {data: {file_clicked: null}};
            if (newClickedFile && newClickedFile.rawFile instanceof File)
                newClickedFileT = await convertFileToBase64(newClickedFile)
                    .then((picture64) => ({
                        src: picture64,
                        title: `${newClickedFile.title}`
                    }))
                    .then(transformedFile => ({
                        data: {
                            file_clicked: transformedFile,
                        }
                    }));
            return Promise.resolve(requestHandler(type, resource, {
                ...params,
                data: {
                    ...params.data,
                    ...newFileT.data,
                    ...newClickedFileT.data
                },
            }));
            /* return Promise.all(newFiles.map(convertFileToBase64))
                 .then(base64Pictures => base64Pictures.map((picture64, index) => {
                     return ({
                         src: picture64,
                         title: newFiles[index].title,
                     })
                 }))
                 .then(transformedNewPictures => requestHandler(type, resource, {
                     ...params,
                     data: {
                         ...params.data,
                         files: transformedNewPictures,
                     },
                 }));*/
        }

    }

    return requestHandler(type, resource, params);
};

export default addUploadCapabilities;
